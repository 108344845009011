import React from "react";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "organization",
  pluralName: "organizations",
  pathName: "organizations",
  endpoint: "/admin/organizations",
  serverName: "organization",
  serverPluralName: "organizations",
  displayName: "Organizations",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "users_attributes",
      label: "Users",
      accessor: "users",
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "User",
          accessor: "name",
          formAccessor: "id",
          type: "reference",
          disabled: (action, data, location) => typeof data === "object" && Object.keys(data).length > 0,
          optionsEntity: {
            name: "user",
            labelField: "name",
            valueField: "id",
            filterParams: [
              {
                name: "assigned_to_organization",
                value: false,
                type: "both",
              },
            ],
          },
          isSearchable: true,
          required: true,
          dependee: true,
        },
        {
          identifier: "sync_enabled",
          label: "Sync Enabled",
          accessor: "sync_enabled",
          type: "select",
          options: [
            { label: "True", value: true },
            { label: "False", value: false },
          ],
          dependsOn: "id",
          dependsOnAny: true,
        },
        {
          identifier: "environment",
          label: "Environment",
          accessor: "environment",
          type: "select",
          options: [
            { label: "Live", value: "live" },
            { label: "Test", value: "test" },
            { label: "Development", value: "development" },
          ],
          dependsOn: "id",
          dependsOnAny: true,
        },
      ],
    },
  ],
});