import admin from "./Admin";
import user from "./User";
import searchResult from "./SearchResult";
import searchResponse from "./SearchResponse";
import gcpSearchResponse from "./GcpSearchResponse";
import gtinSearchResponse from "./GtinSearchResponse";
import organization from "./Organization";

const userDefinedEntities = {
  admin,
  user,
  searchResult,
  searchResponse,
  gtinSearchResponse,
  gcpSearchResponse,
  organization,
};

const groups = [
  {
    name: { en: "group1", ar: "الجروب" },
    type: "normal",
    entities: [],
  },
  {
    name: "ungrouped",
    entities: [admin, user, searchResult, organization],
  },
];
export { userDefinedEntities, groups };
