import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "admin/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: "Users",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  showFiltersInDrawer: true,
  titleField: "name",
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "environment",
      label: "Environment",
      accessor: (dataObject) => (dataObject?.environment ? dataObject?.environment : "_"),
      inList: true,
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "sync_enabled",
      label: "Sync Enabled",
      accessor: "sync_enabled",
      inList: true,
      type: "select",
      options: [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "organization_id",
      label: "Organization",
      accessor: (dataObject) => dataObject?.organization?.name,
      formAccessor: (dataObject) => dataObject?.organization?.id,
      type: "reference",
      inList: true,
      hideInEdit: true,
      hideInCreate: true,
      optionsEntity: {
        name: "organization",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "search_type",
      dependee: true,
      resetDependants: ["databases_booleans"],
      label: { en: "Search Type", ar: " الدور" },
      accessor: "search_type",
      required: true,
      type: "select",
      options: [
        {
          value: "vbg",
          label: "Vbg",
        },
        {
          value: "gcp",
          label: "GCP",
        },
      ],
    },
    {
      identifier: "databases_booleans",
      label: "Database access",
      accessor: "databases_booleans",
      dependsOn: "search_type", 
      dependsOnAny: true,
      type: "checkbox",
      checkboxConfig: {
        format: "string",
      },
      options: (dataObject, state, action) => {
        let _options = [
          { value: "2", label: "Grp" },
          { value: "3", label: "Gepir" }
        ]
        if(state.search_type == 'vbg') _options = [
          ..._options, 
          { value: "1", label: "Gs1 Admin" },
          { value: "4", label: "Gi" }
        ]
        return _options
      },
    },
    {
      identifier: "access_types_booleans",
      label: "Access Type",
      accessor: "access_types_booleans",
      type: "checkbox",
      checkboxConfig: {
        format: "string",
      },
      options: [
        { value: "1", label: "Web" },
        { value: "2", label: "API" },
      ],
    },
  ],
  filters: [
    {
      label: "Organization",
      name: "organization_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "organization",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Sync Enabled",
      name: "sync_enabled",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "True", value: true },
          { label: "False", value: false },
        ],
      },
    },
    {
      label: "Environment",
      name: "environment",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Live", value: "live" },
          { label: "Test", value: "test" },
          { label: "Development", value: "development" },
        ],
      },
    },
  ],
});
